export class OktaUser {
    firstName: String;
    lastName: String;
    isInternalUser: boolean;
    rssdid: String;
    emailaddress: String;
    constructor(firstName: String, lastName: String, isInternalUser:boolean, rssdid:String, emailaddress:String) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.isInternalUser = isInternalUser;
        this.rssdid = rssdid;
        this.emailaddress = emailaddress;
    }
}
