// Internal FR - Source Code
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { QualtricsApiService } from '../shared/services/qualtrics-api.service';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SurveyContact } from '../shared/models/survey-contact';


@Component({
  selector: 'app-internal-user-landing-page',
  templateUrl: './internal-user-landing-page.component.html'
})
export class InternalUserLandingPageComponent implements OnInit {

  rssdid: String;
  errorMessages : String [] = [];
  surveyInternalId:String;
  surveyNameList: Map<string, string>;

  constructor(public qualtricsApiService: QualtricsApiService,  @Inject(SESSION_STORAGE) private readonly storage: WebStorageService,
    private readonly router: Router, private readonly spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.surveyInternalId = "-1";
    this.qualtricsApiService.getSurveyNameList().subscribe(data =>{
      this.surveyNameList = data;
    });     

  }
  loginAsBank(){
    this.spinner.show();
    this.errorMessages=[];
    if(this.surveyInternalId === "-1"){
      this.errorMessages.push("Please Select Survey Name");
      this.spinner.hide();
      return;
    }
    if(this.rssdid === null || this.rssdid === undefined){
      this.errorMessages.push("Please Input RSSDID");
      this.spinner.hide();
      return;
    }


    this.qualtricsApiService.findContact(this.rssdid, this.surveyInternalId).subscribe(res=>{
      if(res !== null){
        const surveyContact : SurveyContact = res;
        if(surveyContact.id === "-1"){
          this.errorMessages.push("You are not allowed to access an out-of-district reporter");  
        }
        else if(surveyContact.id === "-2"){
          this.errorMessages.push("You do not have access to the selected survey");
        }
        else{
          this.router.navigate([`dashboard`, this.rssdid, this.surveyInternalId]);  
        }
        this.spinner.hide();
      }
      else{
        this.errorMessages.push("RSSDID is invalid for the selected survey");
        this.spinner.hide();
      }
    },
      err=>{
        this.errorMessages.push("RSSDID Is Invalid");
        this.spinner.hide();                        
      } 
    )  

  }
}
