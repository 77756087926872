import { Component, OnInit, Inject } from '@angular/core';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { OktaAuthService } from '@okta/okta-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-common-header-section',
  templateUrl: './common-header-section.component.html'
})
export class CommonHeaderSectionComponent  {

  constructor(@Inject(SESSION_STORAGE) public storage: WebStorageService, private readonly oktaAuth: OktaAuthService, private readonly router: Router) { }

  async logout() {
    // Terminates the session with Okta and removes current tokens.
    this.storage.remove("acceptDisclaimer");
    this.storage.remove("contact");   
    this.storage.remove("oktaUser");   
    
    if(this.oktaAuth != null){
      this.oktaAuth.closeSession();
      await this.oktaAuth.signOut();
    }
    this.router.navigateByUrl('/logout');
  }

}
