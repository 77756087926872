const host = window.location.hostname;
const port = window.location.port;
const api = '/api/';

const webport = ':' + port;

const rootUrl = window.location.protocol + '//' + host + webport ;;
const apiUrl = rootUrl + api;

let oktaIssuer;
let oktaBaseUrl;
let oktaClientId;
let redirectUri;
let logoutRedirectUri;

oktaIssuer = 'https://frbanks-ext.okta.com/oauth2/aus7id0q2mWwO7Hnx4h6';
oktaBaseUrl = 'https://frbanks-ext.okta.com';
oktaClientId = '0oa7icvc67RKo5Qxr4h6';
redirectUri = window.location.protocol + '//' + host + '/surveyportal/login/callback';
logoutRedirectUri = window.location.protocol + '//' + host + '/surveyportal/logout'


//redirectUri = 'http://localhost:8080/surveyportal/login/callback';
//logoutRedirectUri= 'http://localhost:8080/surveyportal/logout'; 


//if (port === '4200') {
//  redirectUri = 'http://localhost:4200/login/callback';
//  logoutRedirectUri = 'http://localhost:4200/logout'
//}

if(host.indexOf("surveycentral.federalreservesurveys.org") === -1){
  oktaIssuer = 'https://frbanks-ext.okta.com/oauth2/ausie0w8gFnHjULC84h6';
  oktaClientId = '0oahm9lxlSQII2dek4h6';  
}

/*else if (port === '8081' || host.indexOf("surveycentral-test") !== -1){
  oktaIssuer = 'https://frbanks-external.oktapreview.com/oauth2/ausv67kxkuVSKrxCr0h7';
  oktaBaseUrl = 'https://frbanks-external.oktapreview.com';
  oktaClientId = '0oauheajaocGbSEWI0h7'; 
  if (port === '8081') { 
   redirectUri = 'http://localhost:8081/surveyportal/login/callback';
   logoutRedirectUri= 'http://localhost:8081/surveyportal/logout'; 
  } else {
    redirectUri = window.location.protocol + '//' + host + '/surveyportal/login/callback';
    logoutRedirectUri = window.location.protocol + '//' + host + '/surveyportal/logout'
  }
}
*/

if(port === '8080'){
  redirectUri = 'http://localhost:8080/surveyportal/login/callback';
  logoutRedirectUri= 'http://localhost:8080/surveyportal/logout'; 
}
else if (port === '4200'){
  redirectUri = 'http://localhost:4200/login/callback';
  logoutRedirectUri = 'http://localhost:4200/logout'
}

export const environment = {
  production: true,
  apiUrl: apiUrl,
  issuer: oktaIssuer,
  baseUrl: oktaBaseUrl,
  clientId: oktaClientId,
  redirectUri: redirectUri,
  post_logout_redirect_uri: logoutRedirectUri, 
};
