<!-- Internal FR - Source Code -->
<!--<app-common-header-section></app-common-header-section>-->

<div class="internalUserPage">
    <div tabindex="0" autofocus=""></div>
        <div *ngIf="errorMessages?.length > 0" class="active" style="text-align:center;" aria-live="assertive" role="alert">
            <div class="error-message-heading">
                <span class="glyphicon glyphicon-remove"></span>
                <p style="color:#EB0000">Failed to submit. Please fix the following errors and try again:</p>
            </div>
            <div>
                <ul>
                    <li *ngFor="let errorMessage of errorMessages" style="color:#EB0000">
                        {{errorMessage}}
                    </li>
                </ul>
            </div>
        </div>
        <label style="text-align:center;" for="rssdid" class="required">Please Enter RSSDID <i>(required)</i></label>
        <mat-form-field>
            <div style="text-align:center;">
                <input matInput id="rssdid" [(ngModel)]="rssdid" type="text" required="required" aria-required="true">
            </div>
        </mat-form-field>
        <br>
        <label style="text-align:center;" for="surveyInternalId" class="required">Survey Name <i>(required)</i></label>
        <mat-form-field>
            <div style="text-align:center;">
                <mat-select id="surveyInternalId" [(ngModel)]="surveyInternalId" required="required" aria-required="true">
                    <mat-option value="-1" selected="selected"> Please Select Survey Name...</mat-option>
                    <mat-option *ngFor="let surveyName of surveyNameList | keyvalue" [value]="surveyName.key">
                        {{surveyName.value}}
                    </mat-option>
                </mat-select>
            </div>
        </mat-form-field>
        <br>
        <button class="btn btn-primary" (click)="loginAsBank()">Submit</button>
</div>
