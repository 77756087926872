
<!doctype html>
<!-- Internal FR - Source Code -->
<html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="description" content="">
    <meta name="author" content="Federal Reserve Bank of San Francisco">
    <title>Survey Portal Home</title>
    <!-- Bootstrap core CSS -->
	<link href="../assets/css/bootstrap.css" rel="stylesheet">
	  
    <!-- Event Tracking CSS -->
	<link href="../assets/css/survey-portal.css" rel="stylesheet">
<style>
.app_loader{
    position: fixed;
    height: 1px; 
    width: 1px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
  </head>
  <body>

<app-common-header-section></app-common-header-section>
<div style="text-align:center;">
  <h1>
     {{ title }}
  </h1>
</div>
<br>
<!-- Latest CDN production CSS -->
<link href="https://global.oktacdn.com/okta-signin-widget/4.3.2/css/okta-sign-in.min.css" type="text/css" rel="stylesheet"/>
<router-outlet></router-outlet>

<footer class="pt-4 my-md-5 pt-md-5">
  <div class="row">
    <div class="col-12 col-md">
      <small class="d-block mb-3 text-muted text-center">&copy;2021 Federal Reserve Bank of San Francisco Build Version: <span [innerHTML]="'%PROJECT_VERSION%'"></span>
      </small>
      
      <!-- span [innerHTML]="'1.0.0'"></span -->
    </div>
  </div>
</footer>


</body>
</html>



<div class="app_loader">
  <ngx-spinner
  bdOpacity = 1
  bdColor = ""
  size = "medium"
  color = "#000000"
  type = "ball-spin-clockwise-fade"
  [fullScreen] = "false"
  >
  <p style="color: white; width: auto" > Loading... </p>
  </ngx-spinner>
</div>
