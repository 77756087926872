
<!doctype html>
<html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="description" content="">
    <meta name="author" content="Federal Reserve Bank of San Francisco">
    <title>Survey Portal Home</title>
    <!-- Bootstrap core CSS -->
	<link href="../../../assets/css/bootstrap.css" rel="stylesheet">
	  
    <!-- Survey Portal CSS -->
	<link href="../../../assets/css/survey-portal.css" rel="stylesheet">
	  
	  
	<!-- Favicon -->
	<link rel="icon" href="../../../assets/img/favicon/favicon.ico" type="image/gif" sizes="16x16">
	<link rel="icon" type="image/png" sizes="16x16" href="../../../assets/img/favicon/favicon-16x16.png">
	<link rel="icon" type="image/png" sizes="32x32" href="../../../assets/img/favicon/favicon-32x32.png">
	<link rel="icon" type="image/png" sizes="96x96" href="../../../assets/img/favicon/favicon-96x96.png">
	<link rel="icon" type="image/png" sizes="16x16" href="../../../assets/img/favicon/favicon-16x16.png">
	<meta name="msapplication-TileColor" content="#ffffff">
	<meta name="msapplication-TileImage" content="../../../assets/img/favicon/ms-icon-144x144.png">
	<meta name="theme-color" content="#ffffff">
  </head>
  <body>
	<header>
	</header>  

<div class="container" id="maincontent">
	<img class="disclaimer-icon" src="assets/img/error-icon.svg" alt="Error Icon">
	<h1 class="disclaimer-h1">You are not authorized to access information</h1>
			
</div>

</body>
</html>
