import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from "ngx-spinner";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StorageServiceModule } from 'angular-webstorage-service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login.component';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { HomeModule } from './home/home.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { AuthInterceptor } from './shared/interceptor/auth-interceptor';
import { InternalUserLandingPageModule } from './internal-user-landing-page/internal-user-landing-page.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MatDialogModule } from '@angular/material/dialog';
import { LogoutComponent } from './logout/logout.component';
import { environment } from 'src/environments/environment.prod';
import { ErrorComponent } from './error/error.component';
import { ErrorInterceptor } from './shared/interceptor/error-interceptor';
import { PageNotfoundComponent } from './error/page.notfound.component';

const config = {
  issuer: environment.issuer,
 // redirectUri: window.location.origin + window.location.pathname + 'login/callback',
  redirectUri: environment.redirectUri, 
  clientId: environment.clientId,
  end_session_redirect_uri: environment.post_logout_redirect_uri,
  scopes: ['openid', 'profile', 'email', 'groups', 'address', 'phone', 'survey'],
  oidc:true,
  responseType: 'id_token token'  
}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    LogoutComponent,
    ErrorComponent, 
    PageNotfoundComponent
  ],
  imports: [
    BrowserModule,
    HomeModule,
    InternalUserLandingPageModule,
    StorageServiceModule,
    NgIdleKeepaliveModule.forRoot(),   
    OktaAuthModule,
    MatDialogModule,  
    HttpClientModule, 
    SharedModule, 
    NgxSpinnerModule, 
    BrowserAnimationsModule,     
    AppRoutingModule
  ],
  providers: [
    { provide: OKTA_CONFIG, useValue: config },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },    
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
