<!-- Internal FR - Source Code -->
<!--<app-common-header-section></app-common-header-section>-->
	  
<div class="container" id="maincontent">
	<img class="disclaimer-icon" src="assets/img/alert-icon.svg" alt="Warning Icon">
	<h2 class="disclaimer-h2">If you are not authorized to use this private network, please disconnect immediately.</h2>
	
	<p class="disclaimer-p">Unauthorized access is prohibited and may result in civil and/or criminal prosecution. Users expressly consent to having their activities monitored, recorded, and shared with third parties. By your continued use of this network, you acknowledge that you have read, understood, and agree with this warning message.</p>
	<div class="disclaimer-btns">
		<button class="btn btn-primary btn-lg" (click)="agreeButtonClick()">I Agree</button>
		&nbsp;
		<button class="btn btn-secondary btn-lg" (click)="disagreeButtonClick()">I Disagree</button>
	</div>	
</div>

