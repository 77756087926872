
<h1 mat-dialog-title>Session Time Out Warning</h1>
<div mat-dialog-content style = 'text-align:center;' >
    <p>{{ body }}</p>
</div>
<div class="modal-footer">
    <button mat-button (click)="dialogRef.close(true)" class="btn">OK</button>
    <button mat-button (click)="dialogRef.close(false)" class="btn secondary">Cancel</button>  
</div>

