import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DashboardResolver } from './services/dashboard-resolver';
import { SessionTimeoutDialogComponent } from './session-timeout-dialog/session-timeout-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonHeaderSectionComponent } from './common-header-section/common-header-section.component';

@NgModule({
  declarations: [
  SessionTimeoutDialogComponent,
  CommonHeaderSectionComponent,
],
  
  imports: [
    MatDialogModule,
    MatButtonModule
  ],
  entryComponents: [
    SessionTimeoutDialogComponent
  ],  
  exports: [
    SessionTimeoutDialogComponent, CommonHeaderSectionComponent
  ],  
  providers: [
    DashboardResolver
  ],
})
export class SharedModule { }
