import { Injectable, Inject } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { QualtricsApiUrl } from './qualtrics-api-url';
import { Observable, throwError } from 'rxjs';
import { map, tap, catchError} from 'rxjs/operators';
import { SurveyContact } from '../models/survey-contact';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { DashboardInfo } from '../models/dashboard-info';
import { StringResponse } from '../models/string-response';
import { RetakeInfo } from '../models/retake-info';

@Injectable({
  providedIn: 'root'
})
export class QualtricsApiService  extends BaseService{

  data;
  errorMessage;
  constructor(private readonly _http: HttpClient,  @Inject(SESSION_STORAGE) private readonly storage: WebStorageService) { 
    super();
  }

  getDashboardInfo(orgid: String, surveyids:String): Observable<DashboardInfo>  {
    const queryString = 'orgid=' + orgid + "&surveyids=" + surveyids;  
    return this._http.get<DashboardInfo>(`${QualtricsApiUrl.getDashboardInfo}?${queryString}`, this.httpOptions)
        .pipe(map((response: any) => response),
          tap(result => console.log('Tap Result for getDashboardInfo() ------', result) ),
          catchError(
            err => {
              const val = err;
              throw throwError(err);            
            }            
          )
        );
  }

  getContactInfo(orgid: String, emailaddress: String): Observable<SurveyContact>{
    const queryString = 'orgid=' + orgid + "&emailaddress=" + emailaddress;       
    return this._http.get<SurveyContact>(`${QualtricsApiUrl.getContactInfo}?${queryString}`, this.httpOptions)
        .pipe(
          map((response: any) => response),
          tap(result => console.log('Tap Result for getContactInfo() ------', result)),
          catchError(err => {throw throwError(err);})
        );  }


  getSurveyNameList(): Observable<Map<string, string>>  {
    return this._http.get<String>(`${QualtricsApiUrl.getSurveyNameList}`, this.httpOptions)
        .pipe(
          map((response: any) => response),
          tap(result => console.log('Tap Result for getSurveyNameList() ------', result)),
          catchError(err => {throw throwError(err);})
        );  
  }

  findContact(orgid: String, internalid : String ): Observable<SurveyContact>  {
    const queryString = 'orgid=' + orgid + "&internalid=" + internalid;       
    return this._http.get<SurveyContact>(`${QualtricsApiUrl.findContact}?${queryString}`, this.httpOptions)
        .pipe(
          map((response: any) => response),
          tap(result => console.log('Tap Result for findContact() ------', result)),
          catchError(err => {throw throwError(err);})
        );  
  }

  public enableRetake( retakeInfo : RetakeInfo)  : Observable<boolean> {
    return this._http.post<boolean>(`${QualtricsApiUrl.enableRetake}`, retakeInfo, this.httpOptions)
  }  

}

