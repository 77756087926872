import { SurveyResponseHistory } from './survey-response-history';
import { EmailHistory } from './email-history';

export class RetakeInfo {
    distributionId: String;
    responseId: String;
    enabledDate:Date;
    surveyId: String;
    surveyName:String;
    orgid:String;
    contactId:String;
}
