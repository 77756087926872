import { Component, OnInit, Inject } from '@angular/core';
import { QualtricsApiService } from '../shared/services/qualtrics-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardInfo } from '../shared/models/dashboard-info';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { SurveyResponseHistory } from '../shared/models/survey-response-history';
import { RetakeInfo } from '../shared/models/retake-info';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  isInternalUser:boolean = false;
  //surveyEmailHistoryList: 
  dashboardInfo: DashboardInfo;
  firstName: String;
  lastName: String;
  emailAddress: String;
  onAuthRequired;
  constructor(private readonly qualtricsApiService: QualtricsApiService,  private readonly router: Router,
     @Inject(SESSION_STORAGE) public storage: WebStorageService, protected route: ActivatedRoute, private readonly spinner: NgxSpinnerService ) { 
        const oktaUser = this.storage.get("oktaUser");
        this.isInternalUser = oktaUser.isInternalUser;
        this.firstName = oktaUser.firstName;
        this.lastName = oktaUser.lastName;
        this.emailAddress = oktaUser.emailaddress;
     }

  ngOnInit() {
    this.spinner.show();
    this.dashboardInfo = this.route.snapshot.data['dashboard'];         
    this.spinner.hide();              
  }

  backToInternalUser(){
    this.router.navigateByUrl('internaluserlanding');
  }

  enableRetake(surveyResponseHistory :SurveyResponseHistory){
    let retakeInfo = new RetakeInfo();
    retakeInfo.distributionId = surveyResponseHistory.distributionId
    retakeInfo.enabledDate = new Date();
    retakeInfo.responseId = surveyResponseHistory.responseId;
    retakeInfo.surveyId = surveyResponseHistory.surveyId;
    retakeInfo.surveyName = surveyResponseHistory.surveyName;
    retakeInfo.orgid = this.dashboardInfo.orgid;
    this.qualtricsApiService.enableRetake(retakeInfo).subscribe(results=>{
      if(results){
        window.location.reload();
      }
      else{
        console.log("failed to enable retake");
      }
    });
  }

  showRetake(surveyResponseHistory :SurveyResponseHistory): boolean{ 

    if(this.dashboardInfo.retakeInfoList.length > 0){
      
      for (var retake of this.dashboardInfo.retakeInfoList) {
        if(retake.distributionId === surveyResponseHistory.distributionId){
          return false;
        }
      }
      return true;
    }
    return true;
  }
}
