import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class UserLoginTrackService {
    private isUserLoggedIn = new Subject<boolean>();

    constructor() {
      this.isUserLoggedIn.next(false);
    }
  
    setUserLoggedIn(isUserLoggedIn: boolean) {
      this.isUserLoggedIn.next(isUserLoggedIn);
    }
  
    getUserLoggedIn(): Observable<boolean> {
      return this.isUserLoggedIn.asObservable();
    }
  }