import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-session-timeout-dialog',
  templateUrl: './session-timeout-dialog.component.html'
})
export class SessionTimeoutDialogComponent {
  body: string;
  constructor(public dialogRef: MatDialogRef<SessionTimeoutDialogComponent>,  
          @Inject(MAT_DIALOG_DATA) public data: any) {
  
     this.body = data;       
  }
}
