import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent {

  errorMessage: string;
  constructor(private router:Router) {
    if(this.router.getCurrentNavigation().extras.state != null){
      this.errorMessage = this.router.getCurrentNavigation().extras.state.data 
    }
  }
/*
  ngOnInit(): void {
  }
*/
  refresh(){
    this.router.navigateByUrl('/home');
  }

}
