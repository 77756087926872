import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { UserLoginTrackService } from 'src/app/shared/services/user-login-track.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { QualtricsApiService } from 'src/app/shared/services/qualtrics-api.service';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html'
})
export class DisclaimerComponent implements OnInit {

  constructor(public qualtricsApiService: QualtricsApiService, private readonly router: Router, @Inject(SESSION_STORAGE) private  readonly storage: WebStorageService, 
    private readonly userLoginTrackService: UserLoginTrackService, private readonly spinner: NgxSpinnerService ) { 
  }

  ngOnInit() {
    this.spinner.show();
    const acceptDisclaimer = this.storage.get("acceptDisclaimer");
    if(acceptDisclaimer != null && acceptDisclaimer === 'true'){
      this.routeBaseOnUserInfo();
    }

  }

  public agreeButtonClick(){
    this.spinner.show();
    this.storage.set('acceptDisclaimer', 'true');
    this.routeBaseOnUserInfo();
  }

  public routeBaseOnUserInfo(){
    this.userLoginTrackService.setUserLoggedIn(true);
    const oktaUser =  this.storage.get("oktaUser");
    if(oktaUser == null){
      this.router.navigate([`/unauthorized`]);    
      this.spinner.hide();
      return;
    } 
    if(oktaUser.isInternalUser){
      this.router.navigate([`/internaluserlanding`]);  
      this.spinner.hide(); 
    }
    else{
      this.router.navigate([`dashboard`, oktaUser.rssdid, 'surveys']);  
/*      
      this.qualtricsApiService.getContactInfo(oktaUser.rssdid, oktaUser.emailaddress).subscribe(
          res=>{
            if(res !== null && res.surveyInternalIds !== null){
              this.storage.set('contact', res);
              this.router.navigate([`dashboard`, oktaUser.rssdid, res.surveyInternalIds]);  
            }
            else{
              this.router.navigate([`/unauthorized`]);    
              this.spinner.hide();
            }
      }); 
      */
    } 
  }

  public disagreeButtonClick(){
    this.router.navigate([`/unauthorized`]);    
  }
}
