import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private readonly router: Router){
    }
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                    } else if(error.status == HttpStatusCode.Forbidden){
                        this.router.navigate([`/unauthorized`]);   
                        return; 
                    } 
                    else{
                        // server-side error
                        errorMessage = `An unexpected network error occurred.  Please wait a few seconds and try again.`;
                    }

                    this.router.navigateByUrl('/error', {state: {data: errorMessage}});
                    return throwError(errorMessage);
                })
            )
    }
}