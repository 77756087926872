import { Component, OnInit, Inject } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { OktaAuthService} from '@okta/okta-angular';
import { QualtricsApiService } from './shared/services/qualtrics-api.service';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { SessionTimeoutDialogComponent } from './shared/session-timeout-dialog/session-timeout-dialog.component';
import { UserLoginTrackService } from './shared/services/user-login-track.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{
  title = 'Survey Portal';
  isAuthenticated: boolean;
  timedOut = false;
  dialogRef: MatDialogRef<SessionTimeoutDialogComponent>;  

  constructor(public oktaAuth: OktaAuthService, public qualtricsApiService: QualtricsApiService, private readonly idle: Idle, 
      @Inject(SESSION_STORAGE) public storage: WebStorageService, public router: Router, private readonly spinner: NgxSpinnerService,
      private readonly userLoginTrackService: UserLoginTrackService, private readonly dialog: MatDialog) {
    // Subscribe to authentication state changes
    this.oktaAuth.$authenticationState.subscribe(
      (isAuthenticated: boolean)  => this.isAuthenticated = isAuthenticated
    );

    this.router.events.subscribe(event => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.spinner.show();
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.spinner.hide();
          break;
        }
        default: {
          break;
        }
      }
    });

    // sets an idle timeout of 900 seconds(15 minutes)
    idle.setIdle(900);
    // sets a timeout period of 30 seconds. after 65 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(30);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => { 
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      if(this.dialogRef != null){
        this.dialogRef.close();
      }
      this.logout();      

    });

    idle.onIdleStart.subscribe(() => {

      this.idle.clearInterrupts();
      this.dialogRef = this.dialog.open(SessionTimeoutDialogComponent, {disableClose: true , 
        data: { body: "countdown"},
      });

      this.dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog is closed. Result='+result);
          if (result){
              this.logout();
          }
          else{
            this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);         
            this.reset();   
          }
      });
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.dialogRef.componentInstance.body = 'You will be logged out in ' + countdown + ' seconds!';
    });

    this.userLoginTrackService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
        this.timedOut = false;
      } else {
        idle.stop();
      }
    })
  }

  async ngOnInit() {
    // Get the authentication state for immediate use
    this.isAuthenticated = await this.oktaAuth.isAuthenticated();  
  }

  async logout() {
    // Terminates the session with Okta and removes current tokens.
    this.storage.remove("acceptDisclaimer");
    this.storage.remove("authenticateUser");
    this.storage.remove("contact");   
    this.storage.remove("oktaUser");   
    
    if(this.oktaAuth != null){
      this.oktaAuth.closeSession();
      await this.oktaAuth.signOut();
    }
    this.router.navigateByUrl('/logout');
  }
  reset() {
    this.idle.watch();
    this.timedOut = false;
  }
}
