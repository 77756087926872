import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login.component';
import { OktaCallbackComponent, OktaAuthGuard } from '@okta/okta-angular';
import { DashboardResolver } from './shared/services/dashboard-resolver';
import { InternalUserLandingPageComponent } from './internal-user-landing-page/internal-user-landing-page.component';
import { LogoutComponent } from './logout/logout.component';
import { ErrorComponent } from './error/error.component';
import { PageNotfoundComponent } from './error/page.notfound.component';
import { AuthenticationGuard } from './shared/guards/authentication.guard';


export function onAuthRequired({ oktaAuth, router }) {
  // Redirect the user to your custom login page
  router.navigate(['/login']);
}

const routes: Routes = [
  {path: 'login/callback', component: OktaCallbackComponent},
  {path: 'logout',  component: LogoutComponent},
  {path: 'login', component: LoginComponent},
  {path: 'error', component: ErrorComponent},
  {path: 'home', loadChildren: './home/home.module#HomeModule'},
  {path: 'internaluserlanding', component: InternalUserLandingPageComponent, 
    canActivate: [ OktaAuthGuard, AuthenticationGuard], data: { onAuthRequired }, runGuardsAndResolvers: 'always'}, 
  {path: 'dashboard/:orgid/:surveyids', component: DashboardComponent, 
    canActivate: [ OktaAuthGuard, AuthenticationGuard], data: { onAuthRequired }, runGuardsAndResolvers: 'always', resolve: {dashboard : DashboardResolver}}, 
  {path: '**', component: PageNotfoundComponent},
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
