import { Component, OnInit } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent {

  constructor(private readonly oktaAuth: OktaAuthService) { }

  login() {
    this.oktaAuth.signInWithRedirect({
      originalUri: '/home'
    });
  }  
}
