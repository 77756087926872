/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes,extraRequire}
 * tslint:disable
 */
import * as i0 from "@angular/core";
import * as i1 from "./authentication.component";
import * as i2 from "@okta/okta-angular";
import * as i3 from "../../shared/services/qualtrics-api.service";
import * as i4 from "@angular/router";
import * as i5 from "ngx-spinner";
import * as i6 from "angular-webstorage-service";
var styles_AuthenticationComponent = [];
var RenderType_AuthenticationComponent = /*@__PURE__*/ i0.ɵcrt({ encapsulation: 2, styles: styles_AuthenticationComponent, data: {} });
export { RenderType_AuthenticationComponent as RenderType_AuthenticationComponent };
export function View_AuthenticationComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_AuthenticationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-authentication", [], null, null, null, View_AuthenticationComponent_0, RenderType_AuthenticationComponent)), i0.ɵdid(1, 114688, null, 0, i1.AuthenticationComponent, [i2.OktaAuthService, i3.QualtricsApiService, i4.Router, i5.NgxSpinnerService, i6.SESSION_STORAGE], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthenticationComponentNgFactory = /*@__PURE__*/ i0.ɵccf("app-authentication", i1.AuthenticationComponent, View_AuthenticationComponent_Host_0, {}, {}, []);
export { AuthenticationComponentNgFactory as AuthenticationComponentNgFactory };

