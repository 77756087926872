<!-- Internal FR - Source Code -->
<!--<app-common-header-section></app-common-header-section>-->
<div *ngIf=!isInternalUser style="display:flex;">
        <div class="contactInfo">
            <dl>
                <dt>Please confirm your business contact information is accurate. If updates are required,</dt> 
                <dt>please contact us at (212) 720-6226 or federalreserve.surveys@ny.frb.org</dt>
            </dl> 
                <table style="width: 100%">
                    <tr>
                        <td><strong>First Name:</strong></td><td>{{dashboardInfo.contactInfo?.firstName}}</td>
                        <td><strong>Last Name:</strong></td><td>{{dashboardInfo.contactInfo?.lastName}}</td>
                    </tr>
                    <tr>
                        <td><strong>Work Phone:</strong></td><td>{{dashboardInfo.contactInfo?.phoneNumber}}</td>
                        <td><strong>Work Email:</strong></td><td>{{dashboardInfo.contactInfo?.email}}</td>
                    </tr>
                </table>  
        </div>
</div>
 
<div class="container" id="maincontent">
        <div>
                <a *ngIf=isInternalUser href="javascript:void(0);" (click)="backToInternalUser()">< Back to Home Page</a>
                <div><h2>Survey Dashboard <!--for {{storage.get('oktaUser')?.firstName}}--></h2> </div> 
            </div>
        <div class="card">
        <div class="card-header" id="headingOne">
            <button class="accordion-btn" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            <span class="accordion-text">New/In Progress Surveys</span>
            </button> 
        </div>
        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne">		
            <div class="card-body">
                <table class="aqua-table" title="New/In Progress Surveys">
                <thead>
                    <tr>
                    <th scope="col">Survey Name</th>
             <!--       <th scope="col">Survey Id</th> -->
                    <th scope="col">Survey Open Date (Local Timezone)</th>
                    <th scope="col">Survey End Date (Local Timezone)</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let openSurvey of dashboardInfo.surveyEmailHistoryList; let i= index">						
                        <th scope="row" id="OpenSurveyName{{i}}"> 
                            <a *ngIf=isInternalUser href="{{openSurvey?.distributionLink?.link}}&fn={{firstName}}&ln={{lastName}}&em={{emailAddress}}">{{openSurvey.surveyName}}</a>
                            <a *ngIf=!isInternalUser href="{{openSurvey?.distributionLink?.link}}&fn={{dashboardInfo.contactInfo?.firstName}}&ln={{dashboardInfo.contactInfo?.lastName}}&em={{dashboardInfo.contactInfo?.email}}&pn={{dashboardInfo.contactInfo?.phoneNumber}}&title={{dashboardInfo.contactInfo?.title}}&ext={{dashboardInfo.contactInfo?.extension}}&fax={{dashboardInfo.contactInfo?.fax}}">{{openSurvey.surveyName}}</a> 
                        </th>
              <!--          <td>			
                            {{openSurvey.surveyId}} 
                        </td> 
               -->                         
                        <td id="OpenDate{{i}}">			
                            {{openSurvey?.surveyMetadata?.surveyStartDate | date : 'MM/dd/yyyy HH:mm:ss'}}
                        </td> 
                        <td id="CloseDate{{i}}">
                            {{openSurvey?.surveyMetadata?.surveyExpirationDate | date : 'MM/dd/yyyy HH:mm:ss'}}                        
                        </td>                                                                                                                                                              
                    </tr>
                </tbody>
                </table>

            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-header" id="headingTwo">
        <button class="accordion-btn" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
        <span class="accordion-text">Completed Surveys</span>
        </button>
        </div>
        <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo">		
            <div class="card-body">
                <table class="aqua-table" title="Completed Surveys">
                <thead>
                    <tr>
                    <th scope="col">Survey Name</th>
                    <th scope="col">Survey Completed Date (Local Timezone)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let finishedSurvey of dashboardInfo.surveyResponseHistoryList; let i= index">						
                        <th scope="row" id="CompleteSurveyName{{i}}">
                            <a href="https://frbsfstar.gov1.qualtrics.com/WRQualtricsControlPanel/Report.php?R={{finishedSurvey.responseId}}&NoStatsTables=1&SID={{finishedSurvey.surveyId}}&ResponseSummary=True" target="_blank" rel="noopener">{{finishedSurvey.surveyName}}</a></th>						
                        <td  *ngIf="isInternalUser && i === 0 && showRetake(finishedSurvey) === true else noretake" id="CompleteDate{{i}}">			
                            {{finishedSurvey.surveyCompletedDate | date : 'MM/dd/yyyy HH:mm:ss'}}
                            <div style='margin-left:4em; display:inline-block;'>			
                                &emsp; <button class="btn btn-primary btn-lg" (click)="enableRetake(finishedSurvey)">Retake</button>
                            </div>                                                                                                                                                          
                        </td>
                        <ng-template #noretake>
                            <td  id="CompleteDate{{i}}">			
                                {{finishedSurvey.surveyCompletedDate | date : 'MM/dd/yyyy HH:mm:ss'}}
                            </td>
                        </ng-template>
                    </tr>
                </tbody>
                </table>

            </div>
        </div>

    </div>
    <div *ngIf="dashboardInfo.retakeInfoList.length > 0" class="card">
        <div class="card-header" id="headingThree">
        <button class="accordion-btn" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
        <span class="accordion-text">Retake Surveys</span>
        </button>
        </div>
        <div id="collapseThree" class="collapse show" aria-labelledby="headingThree">		
            <div class="card-body">
                <table class="aqua-table" title="Retake Surveys">
                <thead>
                    <tr>
                    <th scope="col">Survey Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let retakeInfo of dashboardInfo.retakeInfoList; let i= index">						
                        <th *ngIf="isInternalUser else externalUserRetake" scope="row" id="retakeInfo{{i}}">
                            <a href="https://frbsfstar.gov1.qualtrics.com/jfe/form/{{retakeInfo.surveyId}}?Q_R={{retakeInfo.responseId}}&Q_R_DEL=1&orgid={{dashboardInfo.orgid}}">{{retakeInfo.surveyName}}</a>
                        </th>
                        <ng-template #externalUserRetake>
                            <a href="https://frbsfstar.gov1.qualtrics.com/jfe/form/{{retakeInfo.surveyId}}?Q_R={{retakeInfo.responseId}}&Q_R_DEL=1">{{retakeInfo.surveyName}}</a>
                        </ng-template>						
                    </tr>
                </tbody>
                </table>
            </div>
        </div>
    </div>
</div>