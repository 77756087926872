import { rootUrl } from './service-root-url';


export class QualtricsApiUrl {
  public static getContactInfo = `${rootUrl}getContactInfo`;        
  public static getDashboardInfo = `${rootUrl}getDashboardInfo`;        
  public static getSurveyNameList = `${rootUrl}getSurveyNameList`;
  public static findContact = `${rootUrl}findcontact`;                
  public static enableRetake = `${rootUrl}enableRetake`;                
}
