import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationComponent } from './authentication/authentication.component';
import { OktaAuthGuard } from '@okta/okta-angular';
import { SharedModule } from '../shared/shared.module';
import { AuthenticationGuard } from '../shared/guards/authentication.guard';

const routes: Routes = [
  { path: '', component: AuthenticationComponent , canActivate: [ OktaAuthGuard ] }, 
  { path: 'disclaimer', component: DisclaimerComponent , canActivate: [ OktaAuthGuard, AuthenticationGuard]}, 
  { path: 'unauthorized', component: UnauthorizedComponent } 
];

@NgModule({
  declarations: [DisclaimerComponent, UnauthorizedComponent, AuthenticationComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)    
  ]
})
export class HomeModule { }
