import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { OktaAuthService } from '@okta/okta-angular';
import { QualtricsApiService } from 'src/app/shared/services/qualtrics-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { OktaUser } from 'src/app/shared/models/okta-user';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html'
})
export class AuthenticationComponent implements OnInit {
  isAuthenticated: boolean;
  constructor(public oktaAuth: OktaAuthService, public qualtricsApiService: QualtricsApiService, 
    private readonly router: Router, private readonly spinner: NgxSpinnerService,
    @Inject(SESSION_STORAGE) private readonly storage: WebStorageService ) { }

  async ngOnInit() {
    if(this.storage.get("contact") !== null){
      this.router.navigate([`disclaimer`]);
    }
    else{
      this.spinner.show();
      this.isAuthenticated = await this.oktaAuth.isAuthenticated();
      if(this.isAuthenticated){
        this.oktaAuth.getUser().then(info => {
          // this will need to be changed to the role
          if(info.groups_id === null || info.groups_id === undefined){
            this.router.navigate([`unauthorized`]);
            this.spinner.hide();
          }
          else if(info.groups_id.indexOf('National Survey Portal-Analyst') !== -1 || info.groups_id.indexOf('National Survey Portal (Non-Prod)-Analyst') !== -1
            ||info.groups_id.indexOf('National Survey Portal-Power Analyst') !== -1 || info.groups_id.indexOf('National Survey Portal (Non-Prod)-Power Analyst') !== -1  ){
              const oktaUser = new OktaUser(info.given_name, info.family_name, true, info.rssdid, info.email);
              this.storage.set("oktaUser", oktaUser);
              this.storage.set('authenticateUser', 'true');
              this.router.navigate([`disclaimer`]);
              this.spinner.hide();   
         }          
         else if(info.groups_id.indexOf('National Survey Portal-Survey Respondent') !== -1 || info.groups_id.indexOf('National Survey Portal (Non-Prod)-Survey Respondent') !== -1){
            const oktaUser = new OktaUser(info.given_name, info.family_name, false, info.rssdid, info.email);
            this.storage.set("oktaUser", oktaUser);
            this.storage.set('authenticateUser', 'true');
            this.router.navigate([`disclaimer`]);
            this.spinner.hide();
          }      
          else{
            this.router.navigate([`unauthorized`]);
            this.spinner.hide();
          }
        });  
      }
      else{
        this.router.navigate([`unauthorized`]);
        this.spinner.hide();
      }
    }
  }

}
